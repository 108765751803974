import React, { useState } from "react";
import loading from '../../images/loading40.gif';
import './Loader.css';

const Loading = () => {
  return (
    <div className="loaderHolder">
    <div className="loaderMain">
    <div id="loading" className="loading-wrap" style={{display:'block'}}>
        <ul className="loading">
          <li><img src={loading} /></li>
          <li>Loading...</li>
          <br></br>
          <li><span id="progress"></span></li>
        </ul>
      </div>
    </div>
    </div>
  );
};

const IsLoadingHOC = (WrappedComponent: any) => {
  function HOC(props: any) {
    const [isLoading, setLoading] = useState(false);

    const setLoadingState = (isComponentLoading: boolean) => {
      setLoading(isComponentLoading);
    };

    return (
      <>
        {isLoading && <Loading />}
        <WrappedComponent
          {...props}
          isLoading={isLoading}
          setLoading={setLoadingState}
        />
      </>
    );
  }
  return HOC;
};

export default IsLoadingHOC;
