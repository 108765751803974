import React from 'react';
import Logo from "../logo.png"
import "../css/loader.css";

const SuspenseLoader = () => {
    return (
        <div className="preloader">
            <img src={Logo} alt="logo" />
            {/* <div className="loader"></div> */}
        </div>
    )
}

export default SuspenseLoader;