import React, { useState, useEffect, useContext } from "react";
import { useAppSelector,useAppDispatch } from "../redux/hooks";
import { toast } from "react-toastify";
import { encryptText } from "../common/config";
import { updatePassword, validatePassword } from '../redux/auth/authApi';
import { handleLogout } from "../redux/index";
import ChangePasswordDesktop from './ChangePasswordDesktop';
import ChangePasswordMobile from './ChangePasswordMobile';
import { ChangePasswordPayload } from '../model/Account';
import IsLoadingHOC from "../component/IsLoadingHOC/IsLoadingHOC";
import { MessageContext } from "../context/MesssageContext";
const ChangePassword: React.FC<any> = (props) => {
  const context = useContext(MessageContext);
  if (!context) {
      throw new Error('SomeComponent must be used within a MessageProvider');
  }
  const { setErrorContent } = context;
  let { setLoading } = props; 
  const dispatch = useAppDispatch();
  const breakpoint = 620;
  const [selectedTheme, setSelectedTheme] = useState<string[]>((window.innerWidth<620)?['css/UpdatePasswordMobile.css']:['css/UpdatePassword.css','css/bootstrap_5.min.css']);
  const [width, setWidth] = useState(window.innerWidth);
  let [params,setParams] = useState<ChangePasswordPayload>({LevelNumber:0,NewPassword:'',ConfirmPassword:'',OldPassword:'',errorMessage:''});
  
  const ApplyStyle = (loadStyle:string[]) => {
    var children = document.querySelectorAll("link[dynamic_style='dynamic_style']");
    var childArray = Array.prototype.slice.call(children);
    childArray.forEach(function(child){
      child.parentNode.removeChild(child);
    });
    loadStyle.map((style)=>{
      var element = document.createElement("link");
      element.setAttribute("rel", "stylesheet");
      element.setAttribute("type", "text/css");
      element.setAttribute("dynamic_style", "dynamic_style");
      element.setAttribute("href", `${window.location.origin+'/'+style}`);
      document.getElementsByTagName("head")[0].appendChild(element);
    });
  }
  
  const handleSubmit = (params:ChangePasswordPayload)=>{
    if(params.errorMessage!=""){
      setErrorContent(params.errorMessage);
      return;
    }
    else if (params.NewPassword != params.ConfirmPassword) {
      setErrorContent("Confirm Password is not matched!");
      return;
    }
    else{
      let changePasswordParams = {...params,OldPassword:encryptText(params.OldPassword),NewPassword:encryptText(params.NewPassword)}
      updatePassword(changePasswordParams).then((res: any)=>{
        setLoading(false);
        dispatch(handleLogout())
      },(err: any)=>{
        setErrorContent(err);
      })
      .catch((err: any) => {
        setErrorContent(err);
      });
    }
 }
 useEffect(()=>{
  ApplyStyle(selectedTheme);
 },[]);
  return (
    <>
      {width <= breakpoint ? <ChangePasswordMobile params={params} setParams={setParams}  handleSubmit={handleSubmit} /> : <ChangePasswordDesktop params={params} setParams={setParams}  handleSubmit={handleSubmit}/>}
    </>
  );
}

export default IsLoadingHOC(ChangePassword);