import React,{useContext} from 'react';
import { useAppSelector } from "../redux/hooks";
import { Helmet } from 'react-helmet-async';
import { ChangePasswordProps } from '../model/Account';
import { validatePassword } from '../redux/auth/authApi';
import logo from "../images/logo_mobile.png";
import { MessageContext } from "../context/MesssageContext";
const ChangePasswordMobile: React.FC<ChangePasswordProps> = ({handleSubmit, params, setParams}) => {
  const context = useContext(MessageContext);
    if (!context) {
        throw new Error('SomeComponent must be used within a MessageProvider');
    }
    const user = useAppSelector((state: any) => state.auth);
    const { errorContent, setErrorContent } = context;
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setParams((prev) => ({ ...prev, [name]: value }));
    };

    const checkField = (event: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>) => {
      const { name, value } = event.target as HTMLInputElement;
      let error = '';
      if(value.trim()!==""){
        switch(name){
          case 'NewPassword':{
            error = validatePassword(value, user.userData?.LoginId);
            if(error.trim()!==""){
             setErrorContent(error);
            }
            else{
              setErrorContent('');
            }
          } break;
          case 'ConfirmPassword':{
            if (value !== params.NewPassword) {
              let error = 'Passwords do not match'; 
              setErrorContent(error);
            } else {
              setErrorContent('');
            }
          } break;
        }
      }
    };

  const handleForm = ()=>{
    handleSubmit(params); 
    setParams({...params,errorMessage:''});
  }
    return (
      <>
      <Helmet>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
    </Helmet>
    <div className='ChangePasswordMobile'>
    <header>
      <img src={logo} />
    </header>
    <div className="login">
      <form className="login">
      <p>Change Password</p>
      <input name="NewPassword"
      onBlur={checkField}
      onChange={handleInputChange}
      value={params.NewPassword}
      id="newPass"
      type="password"
      placeholder="New Password"
      />
      <input name="ConfirmPassword"
      onBlur={checkField}
      onChange={handleInputChange}
      value={params.ConfirmPassword}
      id="confirmPass"
      type="password"
      placeholder=" New Password Confirm"
      />
      <input name="OldPassword"
      onChange={handleInputChange}
      value={params.OldPassword}
      id="currentPass"
      type="password"
      placeholder=" Old Password"
      />
      <button id="submit"  onClick={(e)=>{e.preventDefault(); handleForm()}} className="btn"><b>Change</b></button>
      <div id="passerror" style={{color:'white',fontSize:'medium'}}>{errorContent}</div>
      </form>
        <div>
            <ul className="note">
                <li>Password must have 8 to 15 alphanumeric without white space</li>
                <li>Password cannot be the same as username/nickname</li>
                <li>Must contain at least 1 capital letter, 1 small letter and 1 number</li>
                <li>Password must not contain any special characters (!,@,#,etc...)</li>
            </ul>
        </div>
    </div>
        </div>
      </>
    );
}
export default ChangePasswordMobile;